<template>
  <v-card
    :style="
      `border: .1rem solid #eee; border-radius: 8px; height: ${sizes.cardHeight}; width: ${sizes.cardWidth};`
    "
    class="ft-avenir lg:text-xs xl:text-sm"
    elevation="0"
    :ripple="false"
  >
    <!-- <dialog-delete
      v-model="courseDeleteDialog"
      :deleting="deleting"
      :item="course"
      item-text="Course"
      @confirm-remove="removeCourse(true)"
    ></dialog-delete> -->
    <div class="d-flex" style="height: 100%">
      <v-img
        v-if="packageItem.image !== undefined"
        :src="packageItem.image"
        :height="sizes.imageHeight"
        :max-width="sizes.imageWidth"
        style="border-radius: 8px 0 0 8px"
      >
        <div
          style="
            background-color: #fc6222;
            width: 100%;
            height: 30%;
            position: absolute;
            bottom: 0;
            border-radius: 0 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-weight: 600;
            font-size: x-large;
          "
        >
          <div v-if="'discount' in packageItem">
            <span style="color: black; text-decoration: line-through">
              <span style="color: white">{{ packageItem.price }}</span>
            </span>
            <br /><br />
            <span style="color: white">{{ discountedPrice }}</span>
          </div>
          <span v-else>{{ packageItem.price }}</span>
        </div>
      </v-img>

      <div
        v-else
        :style="
          'background: #666666; min-width: ' +
            sizes.imageWidth +
            '; border-top-left-radius: 10px; border-bottom-left-radius: 10px;' +
            'display: flex; flex-flow: column; position: relative; align-items: center; justify-content: center;'
        "
      >
        <div style="height: 70%">
          <div
            id="courseNameImage"
            :style="
              `width: ${$vuetify.breakpoint.lgAndDown ? '84px' : '120px'};
              height: ${$vuetify.breakpoint.lgAndDown ? '84px' : '120px'};
              font-size: ${$vuetify.breakpoint.lgAndDown ? '48px' : '70px'}
              `
            "
          >
            {{ getCourseImageFromTitle(packageItem.title) }}
          </div>
        </div>
        <div
          style="
            background-color: #fc6222;
            width: 100%;
            height: 30%;
            position: absolute;
            bottom: 0;
            border-radius: 0 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-weight: 600;
            font-size: x-large;
          "
        >
          <div v-if="'discount' in packageItem">
            <span style="color: black; text-decoration: line-through">
              <span style="color: white">{{ packageItem.price }}</span>
            </span>
            <br /><br />
            <span style="color: white">{{ discountedPrice }}</span>
          </div>
          <span v-else>{{ packageItem.price }}</span>
        </div>
        <div></div>
      </div>

      <div class="flex-grow-1 px-xl-7 px-lg-4" style="width: 280px">
        <v-container class="pt-0">
          <v-row class="mt-xl-6 mt-lg-5">
            <span
              :style="
                `text-overflow: ellipsis; overflow: hidden; width: calc(100% - 30px);`
              "
              class="ft-avenir xl:text-2xl lg:text-base font-weight-bold"
            >
              {{ packageItem.title }}
            </span>
            <div>
              <v-menu offset-y left>
                <template v-slot:activator="{ on: { click }, attrs }">
                  <div
                    class="clickable"
                    v-bind="attrs"
                    style="width: 30px; height: 20px"
                    @click.stop.prevent="click"
                  >
                    <v-icon
                      style="
                        height: 20px;
                        background-color: transparent;
                        color: #202020;
                      "
                      >mdi-dots-vertical
                    </v-icon>
                  </div>
                </template>
                <v-list class="menu-list">
                  <v-list-item :disabled="disableActions" class="menu-item1">
                    <!-- <v-list-item-title
                      class="lg:text-xs xl:text-sm"
                      class="clickable px-lg-3 px-xl-4"
                      @click="$router.push('course-edit')"
                      >Edit Course</v-list-item-title
                      @click="$router.push({name: 'course-edit', params: {partner_code: course.partner_code, courseId: course.course_id, course: course}})"
                       @click="removeCourse(false)"
                    > -->
                    <v-list-item-title
                      class="clickable pa-lg-3 px-xl-5"
                      @click="
                        $router.push({
                          name: 'package-edit',
                          params: {
                            partner_code: ownPartnerCode,
                            package_id: packageItem.package_id
                          }
                        })
                      "
                      >{{ !$language ? "Edit" : "এডিট করুন" }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider dark class="divider-inset-margin-top"></v-divider>
                  <v-list-item :disabled="disableActions" class="menu-item2">
                    <v-list-item-title
                      class="clickable pa-lg-3 px-xl-5"
                      @click="
                        $router.push({
                          name: 'package-details',
                          params: {
                            partner_code: ownPartnerCode,
                            packageId: packageItem.package_id
                          }
                        })
                      "
                      >{{ !$language ? "Details" : "বিস্তারিত দেখুন" }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>

          <v-row class="mt-xl-8 mt-lg-5">
            <p
              class="line-clamp"
              :style="
                `color: #5b5c5e; -webkit-line-clamp: ${
                  sizes.lineClamp
                }; height: ${sizes.lineClamp * 1.35}em`
              "
            >
              <span class="font-weight-bold" style="color: #202020"> </span>
              <span> </span>
            </p>
          </v-row>
          <v-row
            justify="space-between"
            class="mt-xl-5 mt-lg-2 lg:text-xss xl:text-base"
          >
            <slot name="detail-1">
              <v-chip color="primary"
                >{{ !$language ? "Active Students: " : "এক্টিভ শিক্ষার্থী: "
                }}{{ totalActiveStudents }}
              </v-chip>
            </slot>
            <slot name="detail-2">
              <v-chip outlined color="primary"
                >{{ !$language ? "Courses: " : "কোর্স সংখ্যা: "
                }}{{ totalCourses }}
              </v-chip>
            </slot>
          </v-row>
          <!--            <v-row class="grid grid-cols-4" style="grid-column-gap: 10px">-->
          <!-- <v-row v-if="fetching && !itemCount" justify="center" align="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row> -->
          <v-row
            class="mt-xl-5 mt-lg-4 lg:text-xs xl:text-base"
            :style="{ height: sizes.stat.height }"
          >
            <div
              class="course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center"
              :style="` background-color: #cce2d1;`"
            >
              <p>{{ !$language ? "Total Courses" : "মোট কোর্স" }}</p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '17px' : '24px'
                }"
              >
                {{ totalCourses }}
              </p>
            </div>
            <div
              class="course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center"
              :style="` background-color: #ffdfd2;`"
            >
              <p>{{ !$language ? "Total Batches" : "মোট ব্যাচ" }}</p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '17px' : '24px'
                }"
              >
                {{ totalBatches }}
              </p>
            </div>
            <div
              class="course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center"
              :style="` background-color: #cce7f5;`"
            >
              <p>{{ !$language ? "Total Students" : "মোট শিক্ষার্থী" }}</p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '17px' : '24px'
                }"
              >
                {{ totalActiveStudents }}
              </p>
            </div>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
//import DialogDelete from "@ecf/global/components/DialogDelete"
export default {
  name: "PackageCard",
  props: {
    packageItem: {
      type: Object,
      required: true
    },
    disableActions: {
      type: Boolean,
      default: false
    }
  },
  //components: { DialogDelete },
  /* props: {
    course: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  }, */
  data() {
    return {
      fetching: true,
      deleting: false,
      courseDeleteDialog: false
    };
  },
  computed: {
    courseImage() {
      return "image" in this.packageItem
        ? this.packageItem.image
        : require("../courses/assets/img.png");
    } /*
    itemCount() {
      const count = this.$store.state.courses.itemsCount[this.course.course_id];
      if (!count) return {};
      return count;
    }, */,
    ownPartnerCode() {
      const access = this.$store.state.user.user.access;

      for (let [partner_code, access_] of Object.entries(access)) {
        if (access_.includes("owner")) return partner_code;
      }
      return "";
    },
    totalCourses() {
      return this.packageItem.items.courses.length;
    },
    totalBatches() {
      let batches = 0;
      this.packageItem.items.courses.forEach(course => {
        batches += course.batches.length;
      });
      return batches;
    },
    totalActiveStudents() {
      return 0;
    },
    discountedPrice() {
      let price = Number(this.packageItem.price);
      let discoutType = this.packageItem.discount_type;
      let discount = Number(this.packageItem.discount);
      let priceAfterDiscount = 0;
      if (discoutType == "Amount") {
        priceAfterDiscount = price - discount;
        return Math.ceil(priceAfterDiscount);
      } else if (discoutType == "Percentage") {
        priceAfterDiscount = price - (price * discount) / 100;
        return Math.ceil(priceAfterDiscount);
      } else {
        return price;
      }
    },
    sizes() {
      if (this.small) {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "205px" : "210px",
          cardWidth: this.$vuetify.breakpoint.lgAndDown ? "358px" : "475px",
          bodyFontSize: ".75rem",
          imageWidth: "30%",
          titleFontSize: "1.125rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "12px" : "14px",
          lineClamp: 2
        };
      } else {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "224px" : "320px",
          cardWidth: this.$vuetify.breakpoint.lgAndDown ? "493px" : "710px",
          bodyFontSize: ".875rem",
          imageWidth: this.$vuetify.breakpoint.lgAndDown ? "160px" : "230px",
          imageHeight: this.$vuetify.breakpoint.lgAndDown ? "220px" : "316px",
          titleFontSize: "1.5rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "14px" : "16px",
          lineClamp: 2,
          stat: {
            height: this.$vuetify.breakpoint.lgAndDown ? "60px" : "92px"
          }
        };
      }
    }
  },
  methods: {
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map(w => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    }
  }
  /* async created() {
    try {
      this.fetching = true;
      await getCourseItemsCount(
        this.$store,
        this.course.course_id,
        this.course.partner_code,
      );
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message || e]);
    } finally {
      this.fetching = false;
    }
  }, */
  /* methods: {
    editCourse() {
      this.$root.$emit("alert", [
        undefined,
        "this feature is not implementd yet",
      ]);
    },
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map((w) => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    async removeCourse(isConfirmed) {
      if (!isConfirmed) {
        this.courseDeleteDialog = true;
        return;
      }
      try {
        this.deleting = true;
        await courseService.delete(this.course);
        this.$emit("deleted");
        this.courseDeleteDialog = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },
  }, */
};
</script>

<style lang="scss">
#courseNameImage {
  border: 2px solid #fff;
  border-radius: 50%;
  background: #ffffff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-stat {
  //font-size: 1em;
  font-family: Avenir, sans-serif;
  border-radius: 10px;
  height: 100%;
  flex-grow: 1;
  //min-width: 65px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.menu-list {
  background-color: #0099dc !important;
  padding: 0;
  margin: 0;
  border-radius: 8px 8px !important;
}

.menu-item1 {
  //background-color: #0099dc;
  :hover {
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px !important;
  }

  padding: 0;
  margin: 0;
  min-height: 0 !important;
}

.menu-item2 {
  //background-color: #0099dc;
  :hover {
    background-color: #ffffff;
    border-radius: 0px 0px 8px 8px !important;
  }

  padding: 0;
  margin: 0;
  min-height: 0 !important;
}
</style>
