var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "ft-avenir lg:text-xs xl:text-sm",
      style: `border: .1rem solid #eee; border-radius: 8px; height: ${_vm.sizes.cardHeight}; width: ${_vm.sizes.cardWidth};`,
      attrs: { elevation: "0", ripple: false },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex", staticStyle: { height: "100%" } },
        [
          _vm.packageItem.image !== undefined
            ? _c(
                "v-img",
                {
                  staticStyle: { "border-radius": "8px 0 0 8px" },
                  attrs: {
                    src: _vm.packageItem.image,
                    height: _vm.sizes.imageHeight,
                    "max-width": _vm.sizes.imageWidth,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#fc6222",
                        width: "100%",
                        height: "30%",
                        position: "absolute",
                        bottom: "0",
                        "border-radius": "0 0 0 10px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        color: "#ffffff",
                        "font-weight": "600",
                        "font-size": "x-large",
                      },
                    },
                    [
                      "discount" in _vm.packageItem
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "black",
                                  "text-decoration": "line-through",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "white" } },
                                  [_vm._v(_vm._s(_vm.packageItem.price))]
                                ),
                              ]
                            ),
                            _c("br"),
                            _c("br"),
                            _c("span", { staticStyle: { color: "white" } }, [
                              _vm._v(_vm._s(_vm.discountedPrice)),
                            ]),
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.packageItem.price))]),
                    ]
                  ),
                ]
              )
            : _c(
                "div",
                {
                  style:
                    "background: #666666; min-width: " +
                    _vm.sizes.imageWidth +
                    "; border-top-left-radius: 10px; border-bottom-left-radius: 10px;" +
                    "display: flex; flex-flow: column; position: relative; align-items: center; justify-content: center;",
                },
                [
                  _c("div", { staticStyle: { height: "70%" } }, [
                    _c(
                      "div",
                      {
                        style: `width: ${
                          _vm.$vuetify.breakpoint.lgAndDown ? "84px" : "120px"
                        };
            height: ${_vm.$vuetify.breakpoint.lgAndDown ? "84px" : "120px"};
            font-size: ${_vm.$vuetify.breakpoint.lgAndDown ? "48px" : "70px"}
            `,
                        attrs: { id: "courseNameImage" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getCourseImageFromTitle(_vm.packageItem.title)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#fc6222",
                        width: "100%",
                        height: "30%",
                        position: "absolute",
                        bottom: "0",
                        "border-radius": "0 0 0 10px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        color: "#ffffff",
                        "font-weight": "600",
                        "font-size": "x-large",
                      },
                    },
                    [
                      "discount" in _vm.packageItem
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "black",
                                  "text-decoration": "line-through",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "white" } },
                                  [_vm._v(_vm._s(_vm.packageItem.price))]
                                ),
                              ]
                            ),
                            _c("br"),
                            _c("br"),
                            _c("span", { staticStyle: { color: "white" } }, [
                              _vm._v(_vm._s(_vm.discountedPrice)),
                            ]),
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.packageItem.price))]),
                    ]
                  ),
                  _c("div"),
                ]
              ),
          _c(
            "div",
            {
              staticClass: "flex-grow-1 px-xl-7 px-lg-4",
              staticStyle: { width: "280px" },
            },
            [
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c("v-row", { staticClass: "mt-xl-6 mt-lg-5" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "ft-avenir xl:text-2xl lg:text-base font-weight-bold",
                        style: `text-overflow: ellipsis; overflow: hidden; width: calc(100% - 30px);`,
                      },
                      [_vm._v(" " + _vm._s(_vm.packageItem.title) + " ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on: { click }, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._b(
                                        {
                                          staticClass: "clickable",
                                          staticStyle: {
                                            width: "30px",
                                            height: "20px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return click.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        "div",
                                        attrs,
                                        false
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              height: "20px",
                                              "background-color": "transparent",
                                              color: "#202020",
                                            },
                                          },
                                          [_vm._v("mdi-dots-vertical ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "menu-list" },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "menu-item1",
                                    attrs: { disabled: _vm.disableActions },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        staticClass:
                                          "clickable pa-lg-3 px-xl-5",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "package-edit",
                                              params: {
                                                partner_code:
                                                  _vm.ownPartnerCode,
                                                package_id:
                                                  _vm.packageItem.package_id,
                                              },
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Edit"
                                              : "এডিট করুন"
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", {
                                  staticClass: "divider-inset-margin-top",
                                  attrs: { dark: "" },
                                }),
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "menu-item2",
                                    attrs: { disabled: _vm.disableActions },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        staticClass:
                                          "clickable pa-lg-3 px-xl-5",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "package-details",
                                              params: {
                                                partner_code:
                                                  _vm.ownPartnerCode,
                                                packageId:
                                                  _vm.packageItem.package_id,
                                              },
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Details"
                                              : "বিস্তারিত দেখুন"
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("v-row", { staticClass: "mt-xl-8 mt-lg-5" }, [
                    _c(
                      "p",
                      {
                        staticClass: "line-clamp",
                        style: `color: #5b5c5e; -webkit-line-clamp: ${
                          _vm.sizes.lineClamp
                        }; height: ${_vm.sizes.lineClamp * 1.35}em`,
                      },
                      [
                        _c("span", {
                          staticClass: "font-weight-bold",
                          staticStyle: { color: "#202020" },
                        }),
                        _c("span"),
                      ]
                    ),
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-xl-5 mt-lg-2 lg:text-xss xl:text-base",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _vm._t("detail-1", function () {
                        return [
                          _c("v-chip", { attrs: { color: "primary" } }, [
                            _vm._v(
                              _vm._s(
                                !_vm.$language
                                  ? "Active Students: "
                                  : "এক্টিভ শিক্ষার্থী: "
                              ) +
                                _vm._s(_vm.totalActiveStudents) +
                                " "
                            ),
                          ]),
                        ]
                      }),
                      _vm._t("detail-2", function () {
                        return [
                          _c(
                            "v-chip",
                            { attrs: { outlined: "", color: "primary" } },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language
                                    ? "Courses: "
                                    : "কোর্স সংখ্যা: "
                                ) +
                                  _vm._s(_vm.totalCourses) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-xl-5 mt-lg-4 lg:text-xs xl:text-base",
                      style: { height: _vm.sizes.stat.height },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center",
                          style: ` background-color: #cce2d1;`,
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                !_vm.$language ? "Total Courses" : "মোট কোর্স"
                              )
                            ),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                              style: {
                                fontSize: this.$vuetify.breakpoint.lgAndDown
                                  ? "17px"
                                  : "24px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.totalCourses) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center",
                          style: ` background-color: #ffdfd2;`,
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                !_vm.$language ? "Total Batches" : "মোট ব্যাচ"
                              )
                            ),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                              style: {
                                fontSize: this.$vuetify.breakpoint.lgAndDown
                                  ? "17px"
                                  : "24px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.totalBatches) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center",
                          style: ` background-color: #cce7f5;`,
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                !_vm.$language
                                  ? "Total Students"
                                  : "মোট শিক্ষার্থী"
                              )
                            ),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                              style: {
                                fontSize: this.$vuetify.breakpoint.lgAndDown
                                  ? "17px"
                                  : "24px",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.totalActiveStudents) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }