var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-white position-relative card-style",
      staticStyle: {
        "border-color": "transparent",
        "border-radius": "6px",
        height: "100%",
        width: "100%",
      },
    },
    [
      _c(
        "p",
        {
          staticClass: "title-text",
          staticStyle: { height: "40%", color: "#b7b7b7" },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "p",
        {
          staticClass: "d-flex align-center ft-avenir",
          staticStyle: { height: "60%", width: "100%" },
        },
        [
          _c(
            "span",
            { staticClass: "stat-text", staticStyle: { "font-weight": "600" } },
            [_vm._v(_vm._s(_vm.stat))]
          ),
          _c(
            "span",
            { staticClass: "unit-text", staticStyle: { "padding-left": "7%" } },
            [_vm._v(_vm._s(_vm.unit))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }