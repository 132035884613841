var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.renderNow
    ? _c("div", { attrs: { id: "loading-wrapper" } }, [
        _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
        _c("div", { attrs: { id: "loading-content" } }),
      ])
    : _c(
        "v-container",
        {
          staticClass: "pa-0",
          style: {
            marginTop: "28px",
            maxWidth: this.$vuetify.breakpoint.lgAndDown ? "1001px" : "1442px",
          },
        },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.screenSize < 600,
                "background-color": "transparent",
                "show-arrows": false,
                grow: _vm.screenSize < 600,
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "ft-roboto ft-medium tab-style",
                  staticStyle: {
                    "text-transform": "none",
                    "letter-spacing": "0px",
                  },
                  attrs: { href: "#myCourse" },
                },
                [
                  _vm._v(
                    _vm._s(!_vm.$language ? "My Courses" : "আমার কোর্সসমূহ")
                  ),
                ]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "ft-roboto ft-medium tab-style",
                  staticStyle: {
                    "text-transform": "none",
                    "letter-spacing": "1px",
                  },
                  attrs: { href: "#orgCourse" },
                },
                [
                  _vm._v(
                    _vm._s(
                      !_vm.$language
                        ? "Organization Courses"
                        : "প্রাতিষ্ঠানিক কোর্সসমূহ"
                    )
                  ),
                ]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "ft-roboto ft-medium tab-style",
                  staticStyle: {
                    "text-transform": "none",
                    "letter-spacing": "0px",
                  },
                  attrs: { href: "#package" },
                },
                [
                  _vm._v(
                    _vm._s(!_vm.$language ? "My Packages" : "আমার প্যাকেজ")
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            { staticClass: "transparent", attrs: { value: _vm.tab } },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "myCourse" } },
                [
                  _c("v-container", { staticClass: "pa-0" }, [
                    _vm.myStats.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "mt-7 grid",
                            style: {
                              gridTemplateColumns:
                                _vm.screenSize < 801
                                  ? `repeat(2, 1fr)`
                                  : `repeat(4, ${_vm.size.stat.width})`,
                              gridTemplateRows: _vm.size.stat.height,
                              gridGap: _vm.size.stat.gap,
                              height: _vm.size.container.height,
                            },
                          },
                          _vm._l(_vm.myStats, function (stat, n) {
                            return _c("card-stat", {
                              key: n,
                              attrs: {
                                title: stat.title,
                                stat: stat.stat,
                                unit: stat.unit,
                              },
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "pt-5" },
                          [
                            _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-7 d-flex justify-space-between align-center",
                        class: { "flex-column": _vm.screenSize < 600 },
                      },
                      [
                        _c(
                          "div",
                          {
                            style: {
                              width: _vm.size.searchBar.width,
                            },
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "adjustable-height",
                              attrs: {
                                flat: "",
                                solo: "",
                                outlined: "",
                                "prepend-inner-icon": "search",
                                placeholder: !_vm.$language
                                  ? "Search by course title"
                                  : "কোর্স শিরোনাম দিয়ে সার্চ করুন",
                                "hide-details": "",
                                height: _vm.$vuetify.breakpoint.lgAndDown
                                  ? "46px"
                                  : "56px",
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return (() =>
                                    (_vm.courseFilter = _vm.searchKey)).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-inner",
                                  fn: function () {
                                    return [
                                      _c("img", {
                                        style: `padding: ${_vm.size.searchBar.paddingMag}`,
                                        attrs: {
                                          src: require("@ecf/global/assets/img/icons/Search.svg"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.searchKey,
                                callback: function ($$v) {
                                  _vm.searchKey = $$v
                                },
                                expression: "searchKey",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            class: [
                              { "mt-2": _vm.screenSize < 600 },
                              _vm.screenSize < 600
                                ? "justify-space-between"
                                : "justify-end",
                            ],
                            style: `${
                              _vm.screenSize < 600 ? "width: 100%" : ""
                            }`,
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                class: { "mr-2": _vm.screenSize >= 600 },
                                attrs: {
                                  color: "primary",
                                  to: `/teachers/${_vm.ownPartnerCode}/bulk-enrollment`,
                                  depressed: "",
                                  large: _vm.screenSize > 800,
                                  height: _vm.size.btnEnroll.height,
                                  width: _vm.size.btnEnroll.width,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Bulk Enroll"
                                      : "বাল্ক এনরোল"
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  to: `/teachers/${_vm.ownPartnerCode}/courses/create`,
                                  depressed: "",
                                  "min-width": "35",
                                  height: _vm.size.btnCreate.height,
                                  width: _vm.size.btnCreate.width,
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: _vm.size.btnCreate.class,
                                    attrs: { size: _vm.size.btnCreate.icon },
                                  },
                                  [_vm._v("add")]
                                ),
                                _vm.screenSize > 800
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "Create Course"
                                            : "কোর্স তৈরী করুন"
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.myCourses || _vm.myCourses.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex justify-center align-center",
                            staticStyle: { width: "100%", height: "300px" },
                          },
                          [
                            _vm.myCourses === undefined
                              ? _c("v-progress-circular", {
                                  attrs: { primary: "", indeterminate: "" },
                                })
                              : _c(
                                  "div",
                                  { staticStyle: { color: "#202020" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "You don't have any courses"
                                            : "আপনার কোন কোর্স নেই"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        class: [
                          "grid mt-7",
                          _vm.screenSize < 801 ? "grid-cols-1" : "grid-cols-2",
                        ],
                        style: {
                          gridGap: this.$vuetify.breakpoint.lgAndDown
                            ? "16px"
                            : "20px",
                          height: this.$vuetify.breakpoint.lgAndDown
                            ? "500px"
                            : "59vh",
                          overflowY: this.$vuetify.breakpoint.smAndDown
                            ? "visible"
                            : "auto",
                        },
                      },
                      _vm._l(_vm.myCourses, function (course, n) {
                        return _c("card-course", {
                          key: n,
                          staticClass: "clickable",
                          attrs: { course: course },
                          on: {
                            deleted: function ($event) {
                              return _vm.removeMyCourseFromStore(course)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "orgCourse" } },
                [
                  _c("v-container", { staticClass: "pa-0" }, [
                    _vm.orgStats.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "mt-7 grid",
                            style: {
                              gridTemplateColumns:
                                _vm.screenSize < 1264
                                  ? `repeat(2, 1fr)`
                                  : `repeat(4, ${_vm.size.stat.width})`,
                              gridTemplateRows: _vm.size.stat.height,
                              gridGap: _vm.size.stat.gap,
                              height: _vm.size.container.height,
                              overflowX: "hidden",
                            },
                          },
                          _vm._l(_vm.orgStats, function (stat, n) {
                            return _c("card-stat", {
                              key: n,
                              attrs: {
                                title: stat.title,
                                stat: stat.stat,
                                unit: stat.unit,
                              },
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "pt-5" },
                          [
                            _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-7 d-flex justify-space-between align-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            style: {
                              width: _vm.size.searchBar.widthOrg,
                            },
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "adjustable-height",
                              attrs: {
                                flat: "",
                                solo: "",
                                outlined: "",
                                height: _vm.$vuetify.breakpoint.lgAndDown
                                  ? "46px"
                                  : "56px",
                                "prepend-inner-icon": "search",
                                placeholder: !_vm.$language
                                  ? "Search by course title"
                                  : "কোর্স শিরোনাম দিয়ে সার্চ করুন",
                                "hide-details": "",
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return (() =>
                                    (_vm.orgCourseFilter =
                                      _vm.orgSearchKey)).apply(null, arguments)
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-inner",
                                  fn: function () {
                                    return [
                                      _c("img", {
                                        style: `padding: ${_vm.size.searchBar.paddingMag}`,
                                        attrs: {
                                          src: require("@ecf/global/assets/img/icons/Search.svg"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.orgSearchKey,
                                callback: function ($$v) {
                                  _vm.orgSearchKey = $$v
                                },
                                expression: "orgSearchKey",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.orgCourses || _vm.orgCourses.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex justify-center align-center",
                            staticStyle: { width: "100%", height: "300px" },
                          },
                          [
                            _vm.orgCourses === undefined
                              ? _c("v-progress-circular", {
                                  attrs: { primary: "", indeterminate: "" },
                                })
                              : _c(
                                  "div",
                                  { staticStyle: { color: "#202020" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "You are not part of any organizations"
                                            : "আপনি কোনো সংগঠনের সদস্য নন"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        class: [
                          "grid mt-7",
                          _vm.screenSize < 1264 ? "grid-cols-1" : "grid-cols-2",
                        ],
                        style: {
                          gridGap: this.$vuetify.breakpoint.lgAndDown
                            ? "16px"
                            : "20px",
                          height: this.$vuetify.breakpoint.lgAndDown
                            ? "max-content"
                            : "59vh",
                          overflowY: this.$vuetify.breakpoint.smAndDown
                            ? "visible"
                            : "auto",
                        },
                      },
                      _vm._l(_vm.orgCourses, function (course, n) {
                        return _c("card-course", {
                          key: n,
                          attrs: { "disable-actions": true, course: course },
                          on: {
                            deleted: function ($event) {
                              return _vm.removeOrgCourseFromStore(course)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "detail-1",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-chip",
                                      { attrs: { color: "primary" } },
                                      [
                                        _vm._v(
                                          _vm._s(course.partner_name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "detail-2",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-chip",
                                      { attrs: { color: "primary" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Students: "
                                              : "শিক্ষার্থী: "
                                          ) +
                                            _vm._s(course.num_students) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "package" } },
                [
                  _c("v-container", { staticClass: "pa-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mt-7 grid",
                        style: {
                          gridTemplateColumns: `repeat(4, ${_vm.size.stat.width})`,
                          gridTemplateRows: _vm.size.stat.height,
                          gridGap: _vm.size.stat.gap,
                        },
                      },
                      [
                        _c("card-stat", {
                          attrs: {
                            title: !_vm.$language ? "Packages" : "প্যাকেজ",
                            stat: _vm.allPackages,
                            unit: !_vm.$language ? "Packages" : "টি প্যাকেজ",
                          },
                        }),
                        _c("card-stat", {
                          attrs: {
                            title: !_vm.$language
                              ? "Total Courses"
                              : "সকল কোর্স",
                            stat: _vm.packageCourses,
                            unit: !_vm.$language ? "Courses" : "টি কোর্স",
                          },
                        }),
                        _c("card-stat", {
                          attrs: {
                            title: !_vm.$language
                              ? "Active Students"
                              : "সক্রিয় শিক্ষার্থী",
                            stat: _vm.allPackageActiveStudents,
                            unit: !_vm.$language ? "Students" : "জন শিক্ষার্থী",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-7 d-flex justify-space-between align-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            style: {
                              width: this.$vuetify.breakpoint.lgAndDown
                                ? "490px"
                                : "535px",
                            },
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "adjustable-height",
                              attrs: {
                                flat: "",
                                solo: "",
                                outlined: "",
                                "prepend-inner-icon": "search",
                                placeholder: !_vm.$language
                                  ? "Search by package name"
                                  : "প্যাকেজের নাম দ্বারা সার্চ করুন",
                                "hide-details": "",
                                height: _vm.$vuetify.breakpoint.lgAndDown
                                  ? "46px"
                                  : "56px",
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return (() =>
                                    (_vm.packageFilter = _vm.searchKey)).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-inner",
                                  fn: function () {
                                    return [
                                      _c("img", {
                                        staticStyle: {
                                          "padding-left": "20px",
                                          "padding-right": "10px",
                                        },
                                        attrs: {
                                          src: require("@ecf/global/assets/img/icons/Search.svg"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.searchKey,
                                callback: function ($$v) {
                                  _vm.searchKey = $$v
                                },
                                expression: "searchKey",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                style: {
                                  height: _vm.$vuetify.breakpoint.lgAndDown
                                    ? "46px"
                                    : "56px",
                                },
                                attrs: {
                                  color: "primary",
                                  to: `/teachers/${_vm.ownPartnerCode}/packages/create`,
                                  depressed: "",
                                  large: "",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { "x-large": "" },
                                  },
                                  [_vm._v("add")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Create Package"
                                      : "প্যাকেজ তৈরি"
                                  )
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.visiblePackages || _vm.visiblePackages.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-center align-center border border-solid border-radius-8 mt-5",
                            staticStyle: {
                              height: "260px",
                              width: "100%",
                              "border-color": "#c0c0c0",
                            },
                          },
                          [
                            _vm.loadingPackage
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    color: "primary",
                                    indeterminate: "",
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { color: "#202020" },
                                  },
                                  [
                                    _c("p", { staticClass: "grey--text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "You don't have any packages"
                                              : "আপনার কোন প্যাকেজ নেই"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "grid grid-cols-2 mt-7",
                            style: {
                              gridGap: this.$vuetify.breakpoint.lgAndDown
                                ? "16px"
                                : "20px",
                              height: this.$vuetify.breakpoint.lgAndDown
                                ? "500px"
                                : "59vh",
                              overflowY: "auto",
                            },
                          },
                          _vm._l(_vm.visiblePackages, function (pkg) {
                            return _c(
                              "v-container",
                              { key: pkg },
                              [
                                _c("package-card", {
                                  attrs: { "package-item": pkg },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }