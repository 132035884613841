<template>
<div v-if="!renderNow" id="loading-wrapper">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
  <v-container v-else
    class="pa-0"
    :style="{
      marginTop: '28px',
      maxWidth: this.$vuetify.breakpoint.lgAndDown ? '1001px' : '1442px'
    }"
  >
    <v-tabs
      v-model="tab"
      :vertical="screenSize < 600"
      background-color="transparent"
      :show-arrows="false"
      :grow="screenSize < 600"
    >
      <v-tab
        class="ft-roboto ft-medium tab-style"
        style="text-transform: none; letter-spacing: 0px"
        href="#myCourse"
        >{{ !$language ? "My Courses" : "আমার কোর্সসমূহ" }}</v-tab
      >
      <v-tab
        class="ft-roboto ft-medium tab-style"
        style="text-transform: none; letter-spacing: 1px"
        href="#orgCourse"
        >{{
          !$language ? "Organization Courses" : "প্রাতিষ্ঠানিক কোর্সসমূহ"
        }}</v-tab
      >
      <v-tab
        class="ft-roboto ft-medium tab-style"
        style="text-transform: none; letter-spacing: 0px"
        href="#package"
        >{{ !$language ? "My Packages" : "আমার প্যাকেজ" }}</v-tab
      >
    </v-tabs>
    <v-tabs-items :value="tab" class="transparent">
      <v-tab-item value="myCourse">
        <v-container class="pa-0">
          <div v-if="myStats.length > 0"
            class="mt-7 grid"
            :style="{
              gridTemplateColumns:
                screenSize < 801
                  ? `repeat(2, 1fr)`
                  : `repeat(4, ${size.stat.width})`,
              gridTemplateRows: size.stat.height,
              gridGap: size.stat.gap,
              height: size.container.height
            }"
          >
            <card-stat
              v-for="(stat, n) in myStats"
              :key="n"
              :title="stat.title"
              :stat="stat.stat"
              :unit="stat.unit"
            >
            </card-stat>
          </div>
          <div v-else class="pt-5">
            <v-row justify="center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-row>
          </div>
          <div
            class="mt-7 d-flex justify-space-between align-center"
            :class="{ 'flex-column': screenSize < 600 }"
          >
            <div
              :style="{
                width: size.searchBar.width
              }"
            >
              <v-text-field
                v-model="searchKey"
                flat
                solo
                outlined
                prepend-inner-icon="search"
                :placeholder="
                  !$language
                    ? 'Search by course title'
                    : 'কোর্স শিরোনাম দিয়ে সার্চ করুন'
                "
                class="adjustable-height"
                hide-details
                :height="$vuetify.breakpoint.lgAndDown ? '46px' : '56px'"
                @keyup.enter="() => (courseFilter = searchKey)"
              >
                <template v-slot:prepend-inner>
                  <img
                    :style="`padding: ${size.searchBar.paddingMag}`"
                    :src="require('@ecf/global/assets/img/icons/Search.svg')"
                  />
                </template>
              </v-text-field>
            </div>

            <div
              class="d-flex"
              :class="[
                { 'mt-2': screenSize < 600 },
                screenSize < 600 ? 'justify-space-between' : 'justify-end'
              ]"
              :style="`${screenSize < 600 ? 'width: 100%' : ''}`"
            >
              <v-btn
                color="primary"
                :to="`/teachers/${ownPartnerCode}/bulk-enrollment`"
                depressed
                :large="screenSize > 800"
                :class="{ 'mr-2': screenSize >= 600 }"
                :height="size.btnEnroll.height"
                :width="size.btnEnroll.width"
                >{{ !$language ? "Bulk Enroll" : "বাল্ক এনরোল" }}</v-btn
              >
              <v-btn
                color="primary"
                :to="`/teachers/${ownPartnerCode}/courses/create`"
                depressed
                min-width="35"
                :height="size.btnCreate.height"
                :width="size.btnCreate.width"
                ><v-icon
                  :size="size.btnCreate.icon"
                  :class="size.btnCreate.class"
                  >add</v-icon
                ><span v-if="screenSize > 800">{{
                  !$language ? "Create Course" : "কোর্স তৈরী করুন"
                }}</span></v-btn
              >
            </div>
          </div>
          <div
            v-if="!myCourses || myCourses.length === 0"
            style="width: 100%; height: 300px"
            class="d-flex justify-center align-center"
          >
            <v-progress-circular
              v-if="myCourses === undefined"
              primary
              indeterminate
            ></v-progress-circular>
            <div v-else style="color: #202020">
              {{
                !$language
                  ? "You don't have any courses"
                  : "আপনার কোন কোর্স নেই"
              }}
            </div>
          </div>
          <div
            :class="[
              'grid mt-7',
              screenSize < 801 ? 'grid-cols-1' : 'grid-cols-2'
            ]"
            :style="{
              gridGap: this.$vuetify.breakpoint.lgAndDown ? '16px' : '20px',
              height: this.$vuetify.breakpoint.lgAndDown ? '500px' : '59vh',
              overflowY: this.$vuetify.breakpoint.smAndDown ? 'visible' : 'auto'
            }"
          >
            <card-course
              v-for="(course, n) in myCourses"
              :key="n"
              :course="course"
              class="clickable"
              @deleted="removeMyCourseFromStore(course)"
            ></card-course>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item value="orgCourse">
        <v-container class="pa-0">
          <div v-if="orgStats.length > 0"
            class="mt-7 grid"
            :style="{
              gridTemplateColumns:
                screenSize < 1264
                  ? `repeat(2, 1fr)`
                  : `repeat(4, ${size.stat.width})`,
              gridTemplateRows: size.stat.height,
              gridGap: size.stat.gap,
              height: size.container.height,
              overflowX: 'hidden'
            }"
          >
            <card-stat
              v-for="(stat, n) in orgStats"
              :key="n"
              :title="stat.title"
              :stat="stat.stat"
              :unit="stat.unit"
            >
            </card-stat>
          </div>
          <div v-else class="pt-5">
            <v-row justify="center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-row>
          </div>
          <div class="mt-7 d-flex justify-space-between align-center">
            <div
              :style="{
                width: size.searchBar.widthOrg
              }"
            >
              <v-text-field
                v-model="orgSearchKey"
                flat
                solo
                outlined
                class="adjustable-height"
                :height="$vuetify.breakpoint.lgAndDown ? '46px' : '56px'"
                prepend-inner-icon="search"
                :placeholder="
                  !$language
                    ? 'Search by course title'
                    : 'কোর্স শিরোনাম দিয়ে সার্চ করুন'
                "
                hide-details
                @keyup.enter="() => (orgCourseFilter = orgSearchKey)"
              >
                <template v-slot:prepend-inner>
                  <img
                    :style="`padding: ${size.searchBar.paddingMag}`"
                    :src="require('@ecf/global/assets/img/icons/Search.svg')"
                  />
                </template>
              </v-text-field>
            </div>
          </div>
          <div
            v-if="!orgCourses || orgCourses.length === 0"
            style="width: 100%; height: 300px"
            class="d-flex justify-center align-center"
          >
            <v-progress-circular
              v-if="orgCourses === undefined"
              primary
              indeterminate
            ></v-progress-circular>
            <div v-else style="color: #202020">
              {{
                !$language
                  ? "You are not part of any organizations"
                  : "আপনি কোনো সংগঠনের সদস্য নন"
              }}
            </div>
          </div>
          <div
            :class="[
              'grid mt-7',
              screenSize < 1264 ? 'grid-cols-1' : 'grid-cols-2'
            ]"
            :style="{
              gridGap: this.$vuetify.breakpoint.lgAndDown ? '16px' : '20px',
              height: this.$vuetify.breakpoint.lgAndDown ? 'max-content' : '59vh',
              overflowY: this.$vuetify.breakpoint.smAndDown ? 'visible' : 'auto'
            }"
          >
            <card-course
              v-for="(course, n) in orgCourses"
              :key="n"
              :disable-actions="true"
              :course="course"
              @deleted="removeOrgCourseFromStore(course)"
            >
              <template v-slot:detail-1>
                <v-chip color="primary">{{ course.partner_name }} </v-chip>
              </template>
              <template v-slot:detail-2>
                <v-chip color="primary"
                  >{{ !$language ? "Students: " : "শিক্ষার্থী: "
                  }}{{ course.num_students }}
                </v-chip>
              </template>
            </card-course>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item value="package">
        <v-container class="pa-0">
          <div
            class="mt-7 grid"
            :style="{
              gridTemplateColumns: `repeat(4, ${size.stat.width})`,
              gridTemplateRows: size.stat.height,
              gridGap: size.stat.gap
            }"
          >
            <!-- <card-stat
                v-for="(stat, n) in myStats"
                :key="n"
                :title="stat.title"
                :stat="stat.stat"
                :unit="stat.unit"
              >
              </card-stat> -->
            <card-stat
              :title="!$language ? 'Packages' : 'প্যাকেজ'"
              :stat="allPackages"
              :unit="!$language ? 'Packages' : 'টি প্যাকেজ'"
            >
            </card-stat>
            <card-stat
              :title="!$language ? 'Total Courses' : 'সকল কোর্স'"
              :stat="packageCourses"
              :unit="!$language ? 'Courses' : 'টি কোর্স'"
            >
            </card-stat>
            <card-stat
              :title="!$language ? 'Active Students' : 'সক্রিয় শিক্ষার্থী'"
              :stat="allPackageActiveStudents"
              :unit="!$language ? 'Students' : 'জন শিক্ষার্থী'"
            >
            </card-stat>
          </div>
          <div class="mt-7 d-flex justify-space-between align-center">
            <div
              :style="{
                width: this.$vuetify.breakpoint.lgAndDown ? '490px' : '535px'
              }"
            >
              <v-text-field
                v-model="searchKey"
                flat
                solo
                outlined
                prepend-inner-icon="search"
                :placeholder="
                  !$language
                    ? 'Search by package name'
                    : 'প্যাকেজের নাম দ্বারা সার্চ করুন'
                "
                class="adjustable-height"
                hide-details
                :height="$vuetify.breakpoint.lgAndDown ? '46px' : '56px'"
                @keyup.enter="() => (packageFilter = searchKey)"
              >
                <template v-slot:prepend-inner>
                  <img
                    style="padding-left: 20px; padding-right: 10px"
                    :src="require('@ecf/global/assets/img/icons/Search.svg')"
                  />
                </template>
              </v-text-field>
            </div>

            <div class="d-flex justify-end">
              <!-- <v-btn
                color="primary"
                :to="`/${ownPartnerCode}/bulk-enrollment`"
                depressed
                large
                class="mr-2"
                style="width: 190px"
                :style="{
                  height: $vuetify.breakpoint.lgAndDown ? '46px' : '56px',
                }"
                >{{!$language ? 'Bulk Enroll' : 'বাল্ক এনরোল'}}</v-btn
              > -->
              <v-btn
                color="primary"
                :to="`/teachers/${ownPartnerCode}/packages/create`"
                depressed
                large
                :style="{
                  height: $vuetify.breakpoint.lgAndDown ? '46px' : '56px'
                }"
                ><v-icon x-large class="mr-3">add</v-icon
                >{{ !$language ? "Create Package" : "প্যাকেজ তৈরি" }}</v-btn
              >
            </div>
          </div>
          <div
            v-if="!visiblePackages || visiblePackages.length === 0"
            style="height: 260px; width: 100%; border-color: #c0c0c0;"
            class="d-flex justify-center align-center border border-solid border-radius-8 mt-5"
          >
            <v-progress-circular
              v-if="loadingPackage"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <div v-else style="color: #202020;" class="text-center">
              <p class="grey--text">
                {{
                  !$language
                    ? "You don't have any packages"
                    : "আপনার কোন প্যাকেজ নেই"
                }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="grid grid-cols-2 mt-7"
            :style="{
              gridGap: this.$vuetify.breakpoint.lgAndDown ? '16px' : '20px',
              height: this.$vuetify.breakpoint.lgAndDown ? '500px' : '59vh',
              overflowY: 'auto'
            }"
          >
            <v-container v-for="pkg in visiblePackages" :key="pkg">
              <package-card :package-item="pkg"> </package-card>
            </v-container>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import {
  getOrgStats,
  getOrgCourses,
  getOwnCourses,
  getOwnStats
} from "#ecf/courses/actions";
import CardCourse from "#ecf/courses/components/CardCourse";
import CardStat from "#ecf/courses/components/CardStat";
import PackageCard from "../../modules/package/PackageCard.vue";
import PackageService from "#ecf/package/services/PackageService";

export default {
  name: "Courses",
  components: { CardStat, CardCourse, PackageCard },
  props: {},
  data() {
    return {
      renderNow: false,
      username: "Md. Rashed Khan",
      searchKey: "",
      orgSearchKey: "",
      courseFilter: "",
      loadingPackage: true,
      orgCourseFilter: "",
      packageFilter: "",
      packages: []
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      }
    },
    size() {
      return {
        container: {
          height: this.screenSize < 1264 ? "211px" : "115px"
        },
        stat: {
          width: this.$vuetify.breakpoint.lgAndDown ? "233px" : "335px",
          height: this.$vuetify.breakpoint.lgAndDown ? "94px" : "115px",
          gap: this.$vuetify.breakpoint.lgAndDown ? "23px" : "34px"
        },
        searchBar: {
          width:
            this.screenSize < 600
              ? "100%"
              : this.screenSize < 801
              ? "60%"
              : this.screenSize < 1601
              ? "490px"
              : "535px",
          widthOrg:
            this.screenSize < 801
              ? "100%"
              : this.screenSize < 1601
              ? "490px"
              : "535px",
          paddingMag:
            this.screenSize < 801 ? "0px 5px 0px 5spx" : "0px 10px 0px 20px"
        },
        btnEnroll: {
          width:
            this.screenSize < 600
              ? "49%"
              : this.screenSize < 801
              ? "120"
              : "190",
          height:
            this.screenSize < 600 ? "35" : this.screenSize < 1601 ? "45" : "56"
        },
        btnCreate: {
          width:
            this.screenSize < 600
              ? "49%"
              : this.screenSize < 801
              ? "45"
              : "190",
          height:
            this.screenSize < 600 ? "35" : this.screenSize < 1601 ? "45" : "56",
          class: this.screenSize < 801 ? "" : "mr-3",
          icon: this.screenSize < 600 ? 32 : 40
        }
      };
    },
    ownPartnerCode() {
      const access = this.$store.state.user.user.access;

      for (let [partner_code, access_] of Object.entries(access)) {
        if (access_.includes("owner")) return partner_code;
      }
      return "";
    },
    myStats() {
      const stats = this.$store.state.courses.myStats;
      if (stats === undefined) return undefined;
      if (!this.$language) {
        return [
          { title: "Courses", stat: stats.num_courses, unit: "Courses" },
          {
            title: "Ongoing Batches",
            stat: stats.num_batches,
            unit: "Batches"
          },
          {
            title: "Active Students",
            stat: stats.num_students,
            unit: "Students"
          },
          {
            title: "Earning",
            stat: "",
            unit: ""
          }
        ];
      } else {
        return [
          { title: "কোর্সসমূহ", stat: stats.num_courses, unit: "টি কোর্স" },
          {
            title: "চলমান ব্যাচসমূহ",
            stat: stats.num_batches,
            unit: "টি ব্যাচ"
          },
          {
            title: "এক্টিভ শিক্ষার্থী",
            stat: stats.num_students,
            unit: "জন শিক্ষার্থী"
          },
          {
            title: "উপার্জন",
            stat: "",
            unit: ""
          }
        ];
      }
    },
    orgStats() {
      const stats = this.$store.state.courses.orgStats;
      if (stats === undefined) return undefined;
      if (!this.$language) {
        return [
          { title: "Courses", stat: stats.num_courses, unit: "Courses" },
          {
            title: "Organizations",
            stat: stats.num_partners,
            unit: "Organizations"
          },
          { title: "Active Batches", stat: stats.num_batches, unit: "Batches" },
          {
            title: "Earning",
            stat: "",
            unit: ""
          }
        ];
      } else {
        return [
          { title: "কোর্সসমূহ", stat: stats.num_courses, unit: "টি কোর্স" },
          {
            title: "প্রতিষ্ঠান",
            stat: stats.num_partners,
            unit: "টি প্রতিষ্ঠান"
          },
          {
            title: "চলমান ব্যাচসমূহ",
            stat: stats.num_batches,
            unit: "টি ব্যাচ"
          },
          {
            title: "উপার্জন",
            stat: "",
            unit: ""
          }
        ];
      }
    },
    myCourses() {
      if (this.$store.state.courses.myCourses === undefined) return undefined;
      if (!this.courseFilter) {
        return this.$store.state.courses.myCourses;
      } else {
        return this.$store.state.courses.myCourses.filter(c =>
          c.course_title.toLowerCase().includes(this.courseFilter.toLowerCase())
        );
      }
    },
    orgCourses() {
      const orgCourses = this.$store.state.courses.orgCourses;
      if (orgCourses === undefined) return undefined;
      if (!this.orgCourseFilter) {
        return orgCourses;
      } else {
        return orgCourses.filter(c =>
          c.course_title
            .toLowerCase()
            .includes(this.orgCourseFilter.toLowerCase())
        );
      }
    },
    visiblePackages() {
      const visiblePkgs = this.packages;
      if (visiblePkgs.length > 0) {
        if (this.packageFilter) {
          return visiblePkgs.filter(pkg =>
            pkg.title
            .toLowerCase()
            .includes(this.packageFilter.toLowerCase())
          );
        } else {
          return visiblePkgs;
        }
      } else {
        return visiblePkgs;
      }
    },
    allPackages() {
      return this.packages.length;
    },
    packageCourses() {
      let totalCourses = 0;
      if (this.allPackages === 0) {
        return totalCourses;
      } else {
        this.packages.forEach(pkg => {
          totalCourses = totalCourses + pkg.items.courses.length;
        });
        return totalCourses;
      }
    },
    allPackageActiveStudents() {
      return 0;
    }
  },
  async created() {
    const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
    try {
      this.renderNow = false;
      Promise.all([
        getOwnStats(this.$store),
        getOwnCourses(this.$store),
        getOrgCourses(this.$store),
        getOrgStats(this.$store)
      ]).then(()=>{
        this.renderNow = true;
      });
      await this.getPackages();
    } catch (e) {
      this.$root.$emit("alert", ["Alert", e.message]);
    }
    
  },
  mounted() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },
  methods: {
    search(a, n) {},
    removeMyCourseFromStore(course) {
      this.$store.commit("courses/removeMyCourse", {
        courseId: course.course_id
      });
    },
    removeOrgCourseFromStore(course) {
      this.$store.commit("courses/removeOrgCourse", {
        courseId: course.course_id
      });
    },

    async getPackages() {
      let partner_code = this.ownPartnerCode;
      let package_type = "pvt";
      try {
        this.packages = await PackageService.getAllPackages(
        partner_code,
        package_type
      );
      this.loadingPackage = false;
      } catch(e) {
        this.loadingPackage = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.adjustable-height .v-input__slot {
  min-height: auto !important;
}

.tab-style {
  min-width: 30px !important;
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
    padding: 0px;
    height: 33px;
    max-height: 33px;
  }
}
</style>
