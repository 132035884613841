<template>
  <div
    class="bg-white position-relative card-style"
    style="
      border-color: transparent;
      border-radius: 6px;
      height: 100%;
      width: 100%;
    "
  >
    <!-- <p class="ml-9 mt-6 mb-2 lg:text-xs xl:text-lg" style="color: #b7b7b7">
      {{ title }}
    </p>
    <p class="ml-9 mb-4">
      <span class="ft-avenir ft-medium lg:text-2xl xl:text-5xl">{{
        stat
      }}</span>
      <span
        class="ft-avenir ft-light ml-lg-5 ml-xl-7 lg:text-base xl:text-xl"
        >{{ unit }}</span
      >
    </p> -->
    <p style="height: 40%; color: #b7b7b7" class="title-text">
      {{ title }}
    </p>
    <p style="height: 60%; width: 100%" class="d-flex align-center ft-avenir">
      <span style="font-weight: 600" class="stat-text">{{ stat }}</span>
      <span style="padding-left: 7%" class="unit-text">{{ unit }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "CardStat",
  props: {
    title: {
      type: String,
      required: true
    },
    stat: {
      type: [String, Number],
      required: true,
      default: 0
    },
    unit: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.card-style {
  @media screen and (min-width: 1601px) {
    padding: 20px;
    padding-left: 30px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    padding: 15px;
    padding-left: 25px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    padding: 15px;
    padding-left: 20px;
  }

  @media screen and (max-width: 599px) {
    padding: 10px;
    padding-left: 15px;
  }
}

.title-text {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.stat-text {
  @media screen and (min-width: 1601px) {
    font-size: 24px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.unit-text {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}
</style>
