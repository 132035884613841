// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#courseNameImage {\n  border: 2px solid #fff;\n  border-radius: 50%;\n  background: #ffffff;\n  color: #333;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.course-stat {\n  font-family: Avenir, sans-serif;\n  border-radius: 10px;\n  height: 100%;\n  flex-grow: 1;\n}\n.line-clamp {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n.menu-list {\n  background-color: #0099dc !important;\n  padding: 0;\n  margin: 0;\n  border-radius: 8px 8px !important;\n}\n.menu-item1 {\n  padding: 0;\n  margin: 0;\n  min-height: 0 !important;\n}\n.menu-item1 :hover {\n  background-color: #ffffff;\n  border-radius: 8px 8px 0px 0px !important;\n}\n.menu-item2 {\n  padding: 0;\n  margin: 0;\n  min-height: 0 !important;\n}\n.menu-item2 :hover {\n  background-color: #ffffff;\n  border-radius: 0px 0px 8px 8px !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
